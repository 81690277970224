import * as React from "react";
import Button from "../../../shared/button";
import { TermsWrapper, Text } from "./terms.components";

interface Props {
  close: () => void;
}

const Banner: React.FunctionComponent<Props> = ({ close }) => {
  return (
    <TermsWrapper>
      <Text>
        Drodzy Pacjenci,
        <br />
        <br />
        Uprzejmie informujemy, że od dnia 7 listopada 2024 roku wszystkie wizyty
        w KonopnyMed są realizowany wyłącznie w formie wizyt stacjonarnych.
        <br />
        <br />W przypadku pytań lub weryfikacji wolnych terminów prosimy o
        kontakt telefoniczny pod numerem 791265907 lub wiadomość e-mail na adres{" "}
        <a href="konopnymed@gmail.com">konopnymed@gmail.com</a>.
        <br />
        <br />Z poważaniem, <br /> mgr farm. Bartłomiej Zalewa <br /> Prezes
        Zarządu KonopnyMed
      </Text>

      <Button onClick={close}>Zamknij</Button>
    </TermsWrapper>
  );
};

export default Banner;
