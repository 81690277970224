import {
  BackgroundGradient,
  NavbarContainer,
  NavItem,
  NavItemsWrapper,
  PageContentWrapper,
  PageWrapper,
  ScrollToTop,
} from "@packages/ui/components/page";
import type { NextPage } from "next";
import * as React from "react";
import {
  AboutTreatmentSection,
  AboutUsSection,
  CostSection,
  FaqSection,
  IntroductionSection,
  QuestionnaireSection,
  StepsSection,
} from "../components/landing";
import Footer from "../components/landing/footer";
import MobileFooter from "../components/landing/footer/mobile-footer";
import MobileNavigation from "../components/landing/mobile-navigation";
import { MobileNavbar } from "../components/landing/mobile-navigation/mobile-navigation.components";
import Banner from "../components/landing/questionnaire-section/terms/banner";
import Button, { ButtonSize, ButtonVariant } from "../components/shared/button";
import ArrowUp from "../public/images/arrow-up.svg";
import LogoMobile from "../public/images/logo-mobile.svg";
import Logo from "../public/images/logo.svg";
import MenuIcon from "../public/images/mobile-menu.svg";
import useIsSmallDevice from "../utils/hooks/use-is-small-device";

const Home: NextPage = () => {
  const aboutTreatmentRef = React.useRef<HTMLDivElement>(null);
  const costRef = React.useRef<HTMLDivElement>(null);
  const aboutUsRef = React.useRef<HTMLDivElement>(null);
  const faqRef = React.useRef<HTMLDivElement>(null);
  const refs = [aboutTreatmentRef, costRef, aboutUsRef, faqRef];
  const [bannerOpen, setBannerOpen] = React.useState(true);

  const questionnaireRef = React.useRef<HTMLDivElement>(null);

  const sections = [
    "O leczeniu MM",
    "Ile to kosztuje?",
    "O Nas",
    "Godziny pracy Gabinetów",
  ];
  const isSmallDevice = useIsSmallDevice();

  const scrollToQuestionnaire = () => {
    questionnaireRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const [hamburgerOpen, setHamburgerOpen] = React.useState(false);

  return (
    <PageWrapper hamburgerOpen={hamburgerOpen}>
      {hamburgerOpen && (
        <MobileNavigation
          sections={sections}
          scrollToQuestionnaire={() => {
            setHamburgerOpen(false);
            setTimeout(() => scrollToQuestionnaire(), 500);
          }}
          scrollToSection={(i) => {
            setHamburgerOpen(false);
            setTimeout(
              () => refs[i]?.current?.scrollIntoView({ behavior: "smooth" }),
              500,
            );
          }}
          close={() => setHamburgerOpen(false)}
        />
      )}
      <PageContentWrapper>
        {bannerOpen && <Banner close={() => setBannerOpen(false)} />}
        <BackgroundGradient />
        <NavbarContainer>
          {isSmallDevice ? (
            <MobileNavbar>
              <MenuIcon onClick={() => setHamburgerOpen(true)} />
              <LogoMobile />
            </MobileNavbar>
          ) : (
            <Logo />
          )}
          <NavItemsWrapper>
            {sections.map((section, i) => (
              <NavItem
                key={section}
                onClick={() =>
                  refs[i]?.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                {section}
              </NavItem>
            ))}
          </NavItemsWrapper>
          <Button
            onClick={scrollToQuestionnaire}
            size={ButtonSize.Small}
            variant={ButtonVariant.Secondary}
          >
            Umów wizytę
          </Button>
        </NavbarContainer>
        <IntroductionSection>
          <Button onClick={scrollToQuestionnaire} size={ButtonSize.Large}>
            Wypełnij kwestionariusz
          </Button>
        </IntroductionSection>
        <StepsSection />
        <AboutTreatmentSection ref={aboutTreatmentRef} />
        <CostSection ref={costRef}>
          <Button onClick={scrollToQuestionnaire} size={ButtonSize.Large}>
            Wypełnij kwestionariusz
          </Button>
        </CostSection>
        <AboutUsSection ref={aboutUsRef} />
        <FaqSection ref={faqRef} />
        <QuestionnaireSection ref={questionnaireRef} />
        <ScrollToTop
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <ArrowUp />
        </ScrollToTop>
        {isSmallDevice ? <MobileFooter /> : <Footer />}
      </PageContentWrapper>
    </PageWrapper>
  );
};

export default Home;
