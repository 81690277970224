import * as React from "react";
import useIsSmallDevice from "../../../utils/hooks/use-is-small-device";
import { faqElements } from "./faq-elements";
import {
  FaqColumnsWrapper,
  FaqSectionContainer,
  FaqSectionWrapper,
  FaqTile,
  FaqTilesWrapper,
  QuestionAnswer,
} from "./faq-section.components";
import FaqTilesMobile from "./faq-tiles-mobile";

const FaqSection: React.FunctionComponent<
  Record<string, React.RefObject<HTMLDivElement>>
> = React.forwardRef<
  HTMLDivElement,
  Record<string, React.RefObject<HTMLDivElement>>
>((_, ref) => {
  const isSmallDevice = useIsSmallDevice();

  return (
    <FaqSectionContainer ref={ref}>
      <FaqSectionWrapper>
        <h1>Godziny pracy Gabinetów</h1>
        <FaqColumnsWrapper>
          {isSmallDevice ? (
            <FaqTilesMobile />
          ) : (
            faqElements.map((elementGroup, i) => (
              <FaqTilesWrapper key={i}>
                {elementGroup.map((element) => (
                  <FaqTile key={element.question}>
                    <h4>{element.question}</h4>
                    {element.answer.map((answerPart) => (
                      <QuestionAnswer key={answerPart}>
                        {answerPart}
                      </QuestionAnswer>
                    ))}
                  </FaqTile>
                ))}
              </FaqTilesWrapper>
            ))
          )}
        </FaqColumnsWrapper>
      </FaqSectionWrapper>
    </FaqSectionContainer>
  );
});

export default FaqSection;
